.map {
  position: relative;

  .tooltip {
    position: absolute;
    background-color: white;
    padding: 3px;
    border-radius: 3px;

    p {
      padding: 0;
      margin: 0;
    }
  }
  .showOwnership {
    position: absolute;
    top: 105px;
    left: 10px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;

    label {
      display: inline-block;
      padding-right: 10px;
      white-space: nowrap;

      input,
      span {
        vertical-align: middle;
      }
    }
  }
}
