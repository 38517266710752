.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: #fefefe;
    padding: 10px;
    border-radius: 5px;
    width: 50%;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      line-height: 10px;

      &:hover {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
    }

    h2 {
      font-size: 20px;
      margin: 0;
      margin-bottom: 10px;
    }

    section {
      padding: 0;

      .field {
        padding: 5px 0;
        width: 100%;
        display: block;
      }
    }

    footer {
      margin: 10px 0;
      text-align: right;

      > * {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
