body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d4cdab;
  color: #333;
}

label {
  > span {
    white-space: nowrap;
    margin: 0;
    margin-right: 10px;
  }

  .addOn {
    position: relative;
    top: 7px;
    height: 17px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background: #93c0a4;
    background-image: -webkit-linear-gradient(top, #93c0a4, #7da38b);
    background-image: -moz-linear-gradient(top, #93c0a4, #7da38b);
    background-image: -ms-linear-gradient(top, #93c0a4, #7da38b);
    background-image: -o-linear-gradient(top, #93c0a4, #7da38b);
    background-image: linear-gradient(to bottom, #93c0a4, #7da38b);
    padding: 5px 8px;

    &:hover {
      background: #9fceaf;
      background-image: -webkit-linear-gradient(top, #9fceaf, #7da38b);
      background-image: -moz-linear-gradient(top, #9fceaf, #7da38b);
      background-image: -ms-linear-gradient(top, #9fceaf, #7da38b);
      background-image: -o-linear-gradient(top, #9fceaf, #7da38b);
      background-image: linear-gradient(to bottom, #9fceaf, #7da38b);
    }
  }

  input:has(+ .addOn) {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

input {
  border-radius: 5px;
  border: 1px solid #93c0a4;
  border-bottom-color: #7da38b;
  padding: 3px 8px;
  height: 20px;

  &:focus-visible {
    outline: 0;
  }
}

select {
  border-radius: 5px;
  border: 1px solid #93c0a4;
  border-bottom-color: #7da38b;
  padding: 3px 8px;
  height: 26px;

  &:focus-visible {
    outline: 0;
  }
}

button {
  border-radius: 5px;
  border: none;
  background: #93c0a4;
  background-image: -webkit-linear-gradient(top, #93c0a4, #7da38b);
  background-image: -moz-linear-gradient(top, #93c0a4, #7da38b);
  background-image: -ms-linear-gradient(top, #93c0a4, #7da38b);
  background-image: -o-linear-gradient(top, #93c0a4, #7da38b);
  background-image: linear-gradient(to bottom, #93c0a4, #7da38b);
  padding: 3px 8px;

  &:hover {
    background: #9fceaf;
    background-image: -webkit-linear-gradient(top, #9fceaf, #7da38b);
    background-image: -moz-linear-gradient(top, #9fceaf, #7da38b);
    background-image: -ms-linear-gradient(top, #9fceaf, #7da38b);
    background-image: -o-linear-gradient(top, #9fceaf, #7da38b);
    background-image: linear-gradient(to bottom, #9fceaf, #7da38b);
  }
}

table {
  border-collapse: collapse;

  td,
  th {
    font-size: 14px;
    padding: 0 10px;
  }

  th {
    font-weight: bold;
    text-align: left;
    padding-bottom: 2px;
    border-bottom: 1px solid #333;
  }
}

.flex {
  display: flex;
  align-items: stretch;

  &.start {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.end {
    align-items: flex-end;
  }
  &.row {
    flex-direction: row;
    > * {
      margin: 0 10px;
    }
    > *:first-child {
      margin-left: 0;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
  &.column {
    flex-direction: column;
    > * {
      margin: 10px 0;
    }
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
  &.flexGrow {
    flex-grow: 1;
  }
}
