.tradeRoutes {
  h2 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }

  > * {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
