.topBar {
  background-color: #8e9b90;
  padding: 10px 0;
  height: 26px;

  .menuButton {
    padding: 2px;
    margin: 0 10px;
    cursor: pointer;
  }

  h1 {
    font-size: 18px;
    margin: 0;
  }
}

.setupBar {
  background-color: #b6c4a2;
  padding: 10px;

  label {
    margin-right: 10px;
  }

  input {
    width: 40px;
  }
}

nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 46px;
  left: 0;
  background-color: #93c0a4;
  overflow-x: hidden;
  transition: 0.5s;

  &.open {
    width: auto;
  }

  ul {
    position: relative;
    width: 100%;
    list-style: none;
    font-size: 20px;
    padding: 0;
    margin: 10px 20px;

    li {
      cursor: pointer;
      padding: 10px 0;
      width: calc(100% - 40px);
    }
    li.divider {
      cursor: default;
      padding: 0;
      border-bottom: 1px solid #333;
    }
  }
}

section.content {
  width: 100%;
  overflow: auto;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.5;

  .spinner {
    position: fixed;
    left: 50%;
    top: 50%;
  }
}

.react-share__ShareButton {
  margin-left: auto;
  height: 28px !important;
  background-image: none;
  &:hover {
    background-image: none;
  }
}
