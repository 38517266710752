.players {
  h2 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }

  > * {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .colorBlock {
    display: inline-block;
    width: 12px;
    height: 12px;
  }

  tr {
    border-bottom: 1px solid #999;
  }
}
